export function downloadJSONFile(filename, obj) {
  downloadFile(filename, 'data:application/json;charset=utf-8, ' + encodeURIComponent(JSON.stringify(obj)))
}
export function downloadZipFile(filename, blob) {
  downloadFile(filename, getBlobHrefValue(blob))
}
const downloadFile = (name, href) => {
  const element = document.createElement('a')
  element.setAttribute('href',href)
  element.setAttribute('download', name)
  document.body.appendChild(element)
  element.click()
  document.body.removeChild(element)
}
const getBlobHrefValue = blob => window.URL
    .createObjectURL(new Blob([blob]))

